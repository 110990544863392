<template>
  <div>
    <div v-if="loading">Loading...</div>
    <div v-else-if="loggedIn">
      <button class="logout-button" @click="logout">Logout</button>
      <div v-if="isAdmin">
        <button type="button" :class="{ 'active': selectedTab === 'purchaseOrders' }"
          @click="selectedTab = 'purchaseOrders'; selectAllPUnits = false; selectAllWafs = false; selectAllUnits(); selectAllWafers();">Purchase Orders</button>
        <button type="button" :class="{ 'active': selectedTab === 'productionUnits' }"
          @click="selectedTab = 'productionUnits'; selectAllWafs = false; selectAllWafers();">Production Units</button>
        <button type="button" :class="{ 'active': selectedTab === 'wafers' }"
          @click="selectedTab = 'wafers'; selectAllPUnits = false; selectAllUnits()">Wafers</button>
        <button type="button" @click="showNewProductionUnitPopup = true">Create New Production Unit</button>
      </div>
      <div v-else>
        <button type="button" :class="{ 'active': selectedTab === 'productionUnits' }"
          @click="selectedTab = 'productionUnits'">Production Units</button>
        <button type="button" :class="{ 'active': selectedTab === 'wafers' }"
          @click="selectedTab = 'wafers'">Wafers</button>
      </div>
      <div v-if="selectedTab === 'purchaseOrders'" class="purchase-orders">
        <table v-if="isAdmin">
          <thead>
            <tr>
              <th>Name</th>
              <th>Production Units</th>
              <th>Created Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in purchaseOrders" :key="order.id">
              <td>{{ order.purchaseOrderName }}</td>
              <td>{{ order.productionUnits }}</td>
              <td>{{ order.dateCreated }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="selectedTab === 'productionUnits'" class="production-units">
        <div class="filter-div"><button type="button" class="filter-by"
            @click="showFilterOptions = !showFilterOptions">Filter</button></div>
        <div v-if="showFilterOptions">
          <form @submit.prevent="fetchProductionUnits('', 'false', true)" class="filter-by-form">
            <label for="supplier">Supplier:</label>
            <select id="supplier" v-model="pUFSupplierOption">
              <option value="">Choose a supplier</option>
              <option value="Gettop">Gettop</option>
              <option value="I-PEX">I-PEX</option>
              <option value="Merry">Merry</option>
            </select>
            <label for="waferName">Wafer:</label>
            <input type="text" id="waferName" v-model="pUFwaferName">
            <label for="purchaseOrderName">Purchase Order:</label>
            <input type="text" id="purchaseOrderName" v-model="pUFpurchaseOrderName">
            <button type="submit">Apply</button>
          </form>
        </div>
        <table v-if="isAdmin">
          <thead>
            <tr>
              <th>
                <input type="checkbox" v-model="selectAllPUnits" @change="selectAllUnits">
              </th>
              <th>Name <div><button type="button" class="sort-by"
                    @click="fetchProductionUnits('Name', 'true')">&#x25b4;</button><button type="button" class="sort-by"
                    @click="fetchProductionUnits('Name', 'false')">&#x25be;</button></div>
              </th>
              <th>Supplier <div><button type="button" class="sort-by"
                    @click="fetchProductionUnits('Supplier', 'true')">&#x25b4;</button><button type="button"
                    class="sort-by" @click="fetchProductionUnits('Supplier', 'false')">&#x25be;</button></div>
              </th>
              <th>Supplier Type <div><button type="button" class="sort-by"
                    @click="fetchProductionUnits('SupplierType', 'true')">&#x25b4;</button><button type="button"
                    class="sort-by" @click="fetchProductionUnits('SupplierType', 'false')">&#x25be;</button></div>
              </th>
              <th>Incoming Expected Date <div><button type="button" class="sort-by"
                    @click="fetchProductionUnits('IncomingExpected', 'true')">&#x25b4;</button><button type="button"
                    class="sort-by" @click="fetchProductionUnits('IncomingExpected', 'false')">&#x25be;</button></div>
              </th>
              <th>Incoming Actual Date <div><button type="button" class="sort-by"
                    @click="fetchProductionUnits('IncomingActual', 'true')">&#x25b4;</button><button type="button"
                    class="sort-by" @click="fetchProductionUnits('IncomingActual', 'false')">&#x25be;</button></div>
              </th>
              <th>Outgoing Expected Date <div><button type="button" class="sort-by"
                    @click="fetchProductionUnits('OutgoingExpected', 'true')">&#x25b4;</button><button type="button"
                    class="sort-by" @click="fetchProductionUnits('OutgoingExpected', 'false')">&#x25be;</button></div>
              </th>
              <th>Outgoing Actual Date <div><button type="button" class="sort-by"
                    @click="fetchProductionUnits('OutgoingActual', 'true')">&#x25b4;</button><button type="button"
                    class="sort-by" @click="fetchProductionUnits('OutgoingActual', 'false')">&#x25be;</button></div>
              </th>
              <th>Wafers <div><button type="button" class="sort-by"
                    @click="fetchProductionUnits('NumberOfWafers', 'true')">&#x25b4;</button><button type="button"
                    class="sort-by" @click="fetchProductionUnits('NumberOfWafers', 'false')">&#x25be;</button></div>
              </th>
              <th>Die Quantity In</th>
              <th>Die Quantity Out</th>
              <th>Yield</th>
              <th>Supplier Note</th>
              <th>USound Note</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="unit in productionUnits" :key="unit.name">
              <td>
                <input type="checkbox" v-model="selectedUnits" :value="unit.name">
              </td> 
              <td @click="openPopup(unit.name)">{{ unit.name }}</td>
              <td>{{ unit.supplier }}</td>
              <td>{{ unit.supplierType }}</td>
              <td>{{ unit.incomingExpected }}</td>
              <td>{{ unit.incomingActual }}</td>
              <td>{{ unit.outgoingExpected }}</td>
              <td>{{ unit.outgoingActual }}</td>
              <td>{{ unit.numberOfWafers }}</td>
              <td>{{ unit.numberOfDiesIn }}</td>
              <td>{{ unit.numberOfDiesOut }}</td>
              <td>{{ ((Math.round(unit.yield * 100000) / 100000)* 100).toFixed(3)+"%" }}</td>
              <td>{{ unit.supplierNote }}</td>
              <td>{{ unit.internalNote }}</td>
            </tr>
          </tbody>
        </table>
        <table v-else>
          <thead>
            <tr>
              <th>
                <input type="checkbox" v-model="selectAllPUnits" @change="selectAllUnits">
              </th>
              <th>Name <div><button type="button" class="sort-by"
                    @click="fetchProductionUnits('Name', 'true')">&#x25b4;</button><button type="button" class="sort-by"
                    @click="fetchProductionUnits('Name', 'false')">&#x25be;</button></div>
              </th>
              <th>Incoming Expected Date <div><button type="button" class="sort-by"
                    @click="fetchProductionUnits('IncomingExpected', 'true')">&#x25b4;</button><button type="button"
                    class="sort-by" @click="fetchProductionUnits('IncomingExpected', 'false')">&#x25be;</button></div>
              </th>
              <th>Incoming Actual Date <div><button type="button" class="sort-by"
                    @click="fetchProductionUnits('IncomingActual', 'true')">&#x25b4;</button><button type="button"
                    class="sort-by" @click="fetchProductionUnits('IncomingActual', 'false')">&#x25be;</button></div>
              </th>
              <th>Outgoing Expected Date <div><button type="button" class="sort-by"
                    @click="fetchProductionUnits('OutgoingExpected', 'true')">&#x25b4;</button><button type="button"
                    class="sort-by" @click="fetchProductionUnits('OutgoingExpected', 'false')">&#x25be;</button></div>
              </th>
              <th>Outgoing Actual Date <div><button type="button" class="sort-by"
                    @click="fetchProductionUnits('OutgoingActual', 'true')">&#x25b4;</button><button type="button"
                    class="sort-by" @click="fetchProductionUnits('OutgoingActual', 'false')">&#x25be;</button></div>
              </th>
              <th>Wafers <div><button type="button" class="sort-by"
                    @click="fetchProductionUnits('NumberOfWafers', 'true')">&#x25b4;</button><button type="button"
                    class="sort-by" @click="fetchProductionUnits('NumberOfWafers', 'false')">&#x25be;</button></div>
              </th>
              <th>Die Quantity In</th>
              <th>Die Quantity Out</th>
              <th>Yield</th>
              <th>Supplier Note</th>
              <th>USound Note</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="unit in productionUnits" :key="unit.name">
              <td>
                <input type="checkbox" v-model="selectedUnits" :value="unit.name">
              </td>
              <td @click="openPopup(unit.name)">{{ unit.name }}</td>
              <td>{{ unit.incomingExpected }}</td>
              <td>
                <span v-if="!unit.editing">{{ unit.incomingActual }}</span>
                <input v-else type="date" v-model="unit.updatedIncomingActual">

              </td>
              <td>{{ unit.outgoingExpected }}</td>
              <td>
                <span v-if="!unit.editing">{{ unit.outgoingActual }}</span>
                <input v-else type="date" v-model="unit.updatedOutgoingActual">
              </td>
              <td>{{ unit.numberOfWafers }}</td>
              <td>{{ unit.numberOfDiesIn }}</td>
              <td>{{ unit.numberOfDiesOut }}</td>
              <td>{{ ((Math.round(unit.yield * 100000) / 100000)* 100).toFixed(3)+"%" }}</td>
              <td>
                <span v-if="!unit.editing">{{ unit.supplierNote }}</span>
                <input v-else type="text" v-model="unit.updatedSupplierNote">
                <button type="button" @click="toggleEditingProductionUnit(unit)" class="edit-button">
                  <template v-if="unit.editing">
                    <FontAwesomeIcon :icon="nonEditingIcon" />
                  </template>
                  <template v-else>
                    <FontAwesomeIcon :icon="editingIcon" />
                  </template>
                </button>
              </td>
              <td>{{ unit.internalNote }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="popupVisible" class="popup-container">
          <div class="popup-content">
            <!-- Content of your popup goes here -->
            <h2>Production Unit {{ popupUnit.name }}</h2>
            <!-- Example content, replace with your own -->
            <div class="unit-data-container">
              <div>
                <p>Supplier: <strong>{{ popupUnit.supplier }}</strong></p>
                <p>Supplier Note: <strong>{{ popupUnit.supplierNote }}</strong></p>
                <p>USound Note: <strong>{{ popupUnit.internalNote }}</strong></p>
                <p>Purchase Order: <strong>{{ popupUnit.purchaseOrder }}</strong></p>
              </div>
              <div>
                <p>Incoming Expected: <strong>{{ popupUnit.incomingExpected }}</strong></p>
                <p>Incoming Actual: <strong>{{ popupUnit.incomingActual }}</strong></p>
                <p>Outgoing Expected: <strong>{{ popupUnit.outgoingExpected }}</strong></p>
                <p>Outgoing Actual: <strong>{{ popupUnit.outgoingActual }}</strong></p>
              </div>
            </div>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Incoming Dies</th>
                    <th>Outgoing Dies</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in tableRows" :key="index">
                    <td>{{ row.name }}</td>
                    <td>{{ row.incomingDies }}</td>
                    <td>{{ row.outgoingDies }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3><strong>Yield: {{  ((Math.round(popupUnit.yield * 100000) / 100000)* 100).toFixed(3)+"%"  }}</strong></h3>
            <button type="button" @click="popupVisible = false">Close</button>
          </div>
        </div>
      </div>
      <div v-if="selectedTab === 'wafers'" class="wafers">
        <div class="filter-div"><button type="button" class="filter-by"
            @click="showFilterOptions = !showFilterOptions">Filter</button></div>
        <div v-if="showFilterOptions">
          <form @submit.prevent="fetchWafers('', 'false', true)" class="filter-by-form">
            <label for="supplier">Supplier:</label>
            <select id="supplier" v-model="wFSupplierOption">
              <option value="">Choose a supplier</option>
              <option value="Gettop">Gettop</option>
              <option value="I-PEX">I-PEX</option>
              <option value="Merry">Merry</option>
            </select>
            <label for="waferName">Production Unit:</label>
            <input type="text" id="productionUnitName" v-model="wFproductionUnitName">
            <label for="purchaseOrderName">Purchase Order:</label>
            <input type="text" id="purchaseOrderName" v-model="wFpurchaseOrderName">
            <button type="submit">Apply</button>
          </form>
        </div>
        <table v-if="!isAdmin">
          <thead>
            <tr>
              <th>
                <input type="checkbox" v-model="selectAllWafs" @change="selectAllWafers">
              </th>
              <th>ID <div><button type="button" class="sort-by"
                    @click="fetchWafers('Name', 'true')">&#x25b4;</button><button type="button" class="sort-by"
                    @click="fetchWafers('Name', 'false')">&#x25be;</button></div>
              </th>
              <th>Status</th>
              <th>Production Unit</th>
              <th>Incoming Dies</th>
              <th>Outgoing Dies</th>
              <th>Expected Incoming</th>
              <th>Expected Outgoing</th>
              <th>Actual Incoming</th>
              <th>Actual Outgoing</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="wafer in wafers" :key="wafer.name">
              <td>
                <input type="checkbox" v-model="selectedGeneralWafers" :value="wafer">
              </td>
              <td>{{ wafer.name }}</td>
              <td>{{ wafer.status }}</td>
              <td>{{ wafer.productionUnit }}</td>
              <td>{{ wafer.incomingDies }}</td>
              <td>
                <span v-if="!wafer.editing">{{ wafer.outgoingDies }}</span>
                <input v-else type="number" v-model="wafer.updatedOutgoingDies" @blur="updateOutgoingDies(wafer)">
                <button type="button" v-if="isProductionUnitIncluded(wafer.productionUnit)"
                  @click="toggleEditing(wafer)" class="edit-button">
                  <template v-if="wafer.editing">
                    <FontAwesomeIcon :icon="nonEditingIcon" />
                  </template>
                  <template v-else>
                    <FontAwesomeIcon :icon="editingIcon" />
                  </template>
                </button>
              </td>
              <td>{{ wafer.expectedIncoming }}</td>
              <td>{{ wafer.expectedOutgoing }}</td>
              <td>{{ wafer.actualIncoming }}</td>
              <td>{{ wafer.actualOutgoing }}</td>
            </tr>
          </tbody>
        </table>
        <table v-else>
          <thead>
            <tr>
              <th>
                <input type="checkbox" v-model="selectAllWafs" @change="selectAllWafers">
              </th>
              <th>ID <div><button type="button" class="sort-by"
                    @click="fetchWafers('Name', 'true')">&#x25b4;</button><button type="button" class="sort-by"
                    @click="fetchWafers('Name', 'false')">&#x25be;</button></div>
              </th>
              <th>Current Location</th>
              <th>Last Update <div><button type="button" class="sort-by"
                    @click="fetchWafers('LastUpdate', 'true')">&#x25b4;</button><button type="button" class="sort-by"
                    @click="fetchWafers('LastUpdate', 'false')">&#x25be;</button></div>
              </th>
              <th>Current Production Unit <div><button type="button" class="sort-by"
                    @click="fetchWafers('CurrentProductionUnit', 'true')">&#x25b4;</button><button type="button"
                    class="sort-by" @click="fetchWafers('CurrentProductionUnit', 'false')">&#x25be;</button></div>
              </th>
              <th>Available Dies <div><button type="button" class="sort-by"
                    @click="fetchWafers('AvailableDies', 'true')">&#x25b4;</button><button type="button" class="sort-by"
                    @click="fetchWafers('AvailableDies', 'false')">&#x25be;</button></div>
              </th>
              <th>Production Units</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="wafer in wafers" :key="wafer.name">
              <td>
                <input type="checkbox" v-model="selectedGeneralWafers" :value="wafer">
              </td>
              <td>{{ wafer.name }}</td>
              <td>{{ wafer.currentLocation }}</td>
              <td>{{ wafer.lastUpdate }}</td>
              <td>{{ wafer.currentProductionUnit }}</td>
              <td>{{ wafer.availableDies }}</td>
              <td>{{ wafer.productionUnits }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="login-container" v-else>
      <form @submit.prevent="login">
        <label for="username">Username:</label>
        <input type="text" id="username" v-model="username" required>
        <label for="password">Password:</label>
        <input type="password" id="password" v-model="password" required>
        <button type="submit">Login</button>
      </form>
    </div>
    <div v-if="showNewProductionUnitPopup" class="popup-form">
      <form @submit.prevent="currentStep === 1 ? nextStep() : createNewProductionUnit()">
        <div v-if="currentStep === 1">
          <label for="unitName">Name:</label>
          <input type="text" id="unitName" v-model="newUnit.name" required>
          <label for="supplier">Supplier:</label>
          <select id="supplier" v-model="newUnit.supplier" required>
            <option value="">Select Supplier</option>
            <option value="I-PEX">I-PEX</option>
            <option value="Gettop">Gettop</option>
            <option value="Merry">Merry</option>
          </select>
          <label>Wafers:</label>
          <div v-for="wafer in wafers" :key="wafer.name">
            <input type="checkbox" :value="wafer.name" v-model="newUnit.selectedWafers[wafer.name]">
            <label>{{ wafer.name }}</label>
          </div>
          <button type="button" @click="nextStep">Next</button>
          <button type="button" @click="showNewProductionUnitPopup = false">Cancel</button>
        </div>
        <div v-else-if="currentStep === 2">
          <label for="incomingDate">Expected Incoming Date:</label>
          <input type="date" id="incomingDate" v-model="newUnit.incomingExpected" required>
          <label for="outgoingDate">Expected Outgoing Date:</label>
          <input type="date" id="outgoingDate" v-model="newUnit.outgoingExpected" required>
          <label for="note">Note:</label>
          <textarea id="note" v-model="newUnit.note"></textarea>
          <button type="submit">Create</button>
          <button type="button" @click="currentStep = 1">Previous</button>
          <button type="button" @click="showNewProductionUnitPopup = false">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueCookies from 'vue-cookies';
import { toRaw } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPenToSquare, faFloppyDisk } from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    FontAwesomeIcon
  },

  data() {
    return {
      showNewProductionUnitPopup: false,
      showFilterOptions: false,
      currentStep: 1,
      selectedOption: '',
      selectedSupplierOption: '',
      textInput: '',
      newUnit: {
        name: '',
        supplier: '',
        incomingExpected: '',
        outgoingExpected: '',
        note: '',
        selectedWafers: []
      },
      popupUnit: {},
      popupWafers: [],
      loading: false,
      loggedIn: false,
      isAdmin: false,
      username: '',
      password: '',
      usertype: '',
      selectedTab: '',
      purchaseOrders: [],
      productionUnits: [],
      pUFSupplierOption: '',
      pUFWaferName: '',
      pUFpurchaseOrderName: '',
      wFSupplierOption: '',
      wFproductionUnitName: '',
      wFpurchaseOrderName: '',
      wafers: [],
      editingIcon: faPenToSquare,
      nonEditingIcon: faFloppyDisk,
      popupVisible: false,
      selectedUnits: [],
      selectedGeneralWafers: [],
      selectAllPUnits: false,
      selectAllWafs: false
    };
  },
  computed: {
    tableRows() {
      return Object.values(toRaw(this.popupWafers));
    }
  },
  async created() {
    const token = VueCookies.get('token');

    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      this.loggedIn = true;
      // Perform auto-login if token exists
      this.autoLogin();
    }
    this.loading = false;
  },
  methods: {
    isProductionUnitIncluded(unit) {
      const productionUnitNames = Object.values(toRaw(this.productionUnits)).map(item => item.name);
      return productionUnitNames.includes(unit);
    },
    selectAllUnits() {
      if (this.selectAllPUnits) {
        this.selectedUnits = this.productionUnits.map(unit => unit.name);
      } else {
        this.selectedUnits = [];
      }
    },
    selectAllWafers() {
      if (this.selectAllWafs) {
        this.selectedGeneralWafers = this.wafers.map(wafer => wafer);
      } else {
        this.selectedGeneralWafers = [];
      }
    },
    toggleEditing(wafer) {
      wafer.editing = !wafer.editing;
      // If switching from editing to non-editing mode, reset the updated value
      if (!wafer.editing) {
        wafer.updatedOutgoingDies = wafer.outgoingDies;
      }
    },
    toggleEditingProductionUnit(productionUnit) {

      productionUnit.editing = !productionUnit.editing;
      // If switching from editing to non-editing mode, reset the updated value
      if (!productionUnit.editing) {
        this.updateProductionUnit(productionUnit);
        productionUnit.updatedIncomingActual = productionUnit.incomingActual;
        productionUnit.updatedOutgoingActual = productionUnit.outgoingActual;
        productionUnit.updatedSupplierNote = productionUnit.supplierNote;
      }
    },
    async updateOutgoingDies(wafer) {
      try {
       
        await axios.post('https://productiontracking.usound.com/api/Wafer/UpdateWaferOutgoing?waferName=' + wafer.name + '&productionUnitName=' + wafer.productionUnit + '&outgoingDies=' + wafer.updatedOutgoingDies, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        wafer.outgoingDies = wafer.updatedOutgoingDies
        wafer.editing = false;
        this.fetchWafers();
      } catch (error) {
        console.error('Error updating outgoing dies:', error);

      }
    },
    async updateProductionUnit(productionUnit) {
      try {
        await axios.post('https://productiontracking.usound.com/api/ProductionUnit/UpdateProductionUnit?productionUnitName=' + productionUnit.name +
          '&actualIncoming=' + productionUnit.updatedIncomingActual + '&actualOutgoing=' + productionUnit.updatedOutgoingActual + '&supplierNote=' + productionUnit.updatedSupplierNote);

        productionUnit.incomingActual = productionUnit.updatedIncomingActual;
        productionUnit.outgoingActual = productionUnit.updatedOutgoingActual;
        productionUnit.supplierNote = productionUnit.updatedSupplierNote;
        productionUnit.editing = false;
        this.fetchProductionUnits();
      } catch (error) {
        console.error('Error updating productionUnit:', error);

      }
    },
    handleChange() {
      this.selectedSupplierOption = '';
      this.textInput = '';
    },
    async fetchPurchaseOrders() {
      try {
        const response = await axios.post('https://productiontracking.usound.com/api/PurchaseOrder/GetPurchaseOrders?skip=0&limit=100', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        const data = await response.data;
        this.purchaseOrders = data;
      } catch (error) {
        console.error('Error fetching Purch Orders:', error);
      }
    },

    async fetchProductionUnits(orderBy = '', ascending = 'false', filter = false) {
      var apistring = "";
      if (orderBy) {
        apistring += "orderBy=" + orderBy;
      }
      apistring += "&ascending=" + ascending + "&skip=0&limit=100";
      if (filter) {
        if (this.pUFSupplierOption) {
          apistring += "&supplierFilter=";
          apistring += this.pUFSupplierOption;
        }
        if (this.pUFWaferName) {
          apistring += '&waferFilter=';
          apistring += this.pUFWaferName;
        }
        if (this.pUFpurchaseOrderName) {
          apistring += '&purchaseOrderFilter=';
          apistring += this.pUFpurchaseOrderName;
        }
      }
      try {
        const response = await axios.get('https://productiontracking.usound.com/api/ProductionUnit/GetProductionUnits?' + apistring, {
        });
        this.productionUnits = response.data;

      } catch (error) {
        console.error('Error fetching production units:', error);
      }

    },
    async fetchWafers(orderBy = '', ascending = 'false', filter = false) {

      // Call API to get wafers
      // Example response
      var apistring = "";
      if (orderBy) {
        apistring += "orderBy=" + orderBy;
      }
      apistring += "&ascending=" + ascending + "&skip=0&limit=100";
      if (filter) {
        if (this.wFSupplierOption) {
          apistring += "&supplierFilter=";
          apistring += this.wFSupplierOption;
        }
        if (this.wFproductionUnitName) {
          apistring += '&productionUnitFilter=';
          apistring += this.wFproductionUnitName;
        }
        if (this.wFpurchaseOrderName) {
          apistring += '&purchaseOrderFilter=';
          apistring += this.wFpurchaseOrderName;
        }
      }
      var api_call = "GetSupplierWafers";
      if (this.usertype === "USound") {
        api_call = "GetWafers";
      }
      try {
        const response = await axios.get('https://productiontracking.usound.com/api/Wafer/' + api_call + '?' + apistring, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        this.wafers = response.data;
      } catch (error) {
        console.error('Error fetching wafers :', error);

      }
    },
    async openPopup(unitName) {
      try {
        const response = await axios.get('https://productiontracking.usound.com/api/ProductionUnit/GetProductionUnit?name=' + unitName, {
        });
        this.popupUnit = response.data;
      } catch (error) {
        console.error("Error fetching production unit info:", error);
      }

      if (!this.isAdmin) {
        try {
          const response = await axios.get('https://productiontracking.usound.com/api/Wafer/GetSupplierWafers?orderBy=Name&ascending=true&skip=0&limit=100&productionUnitFilter=' + unitName, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          });
          this.popupWafers = Object.values(toRaw(response.data));

        } catch (error) {
          console.error('Error fetching wafers :', error);

        }
      }
      else {
        try {
          const response = await axios.get('https://productiontracking.usound.com/api/Wafer/GetWafers?orderBy=Name&ascending=true&skip=0&limit=100&productionUnitFilter=' + unitName, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          });
          this.popupWafers = Object.values(toRaw(response.data));

        } catch (error) {
          console.error('Error fetching wafers :', error);

        }
      }
      this.popupVisible = true;
    },
   async nextStep() {
      try {
        const response = await axios.post('https://productiontracking.usound.com/api/ProductionUnit/GetAddProductionUnitInfo', 
          (Object.keys(toRaw(this.newUnit.selectedWafers))));
          this.newUnit.incomingExpected = response.data.expectedIncomingDate;

      } catch (error) {
        alert(JSON.stringify("Something went wrong!"));
        console.error('Login failed:', error);
      }
      this.currentStep++;

    },
    async createNewProductionUnit() {
      const selectedWafers = (Object.keys(toRaw(this.newUnit.selectedWafers))).map(wafername => {
        const foundWafer = this.wafers.find(wafer => wafer.name === wafername);
        const dieCount = foundWafer ? foundWafer.availableDies : "Wafer not found";
        return {
          waferName: wafername,
          dieCount: dieCount
        };
      });
      try {

        await axios.put('https://productiontracking.usound.com/api/ProductionUnit/AddProductionUnit?name=' + this.newUnit.name + '&supplierName=' + this.newUnit.supplier + '&expectedIncoming=' + this.newUnit.outgoingExpected + '&expectedOutgoing=' + this.newUnit.incomingExpected + '&note=' + this.newUnit.note, selectedWafers);

        await this.fetchProductionUnits(); 
        alert('Production unit created successfully!');
        this.showNewProductionUnitPopup = false;

      } catch (error) {
        console.error('Error creating production unit:', error);
        alert('An error occurred while creating the production unit.');
      }
      this.currentStep = 1;
      this.showNewProductionUnitPopup = false;
      await this.fetchProductionUnits();
    },

    async login() {

      try {
        const response = await axios.post('https://productiontracking.usound.com/api/login', {
          email: this.username,
          password: this.password,
        }, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        VueCookies.set('token', response.data.accessToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
        this.loggedIn = true;
        this.loading = false; 

      } catch (error) {
        alert(JSON.stringify("Something went wrong! Check your username and password and try again."));
        console.error('Login failed:', error);
      }
      try {
        const response = await axios.get('https://productiontracking.usound.com/api/User/GetUserInfo', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        if (response.data.type === "USound") {
          this.isAdmin = true;
        }
        this.usertype = response.data.type;
        if (this.loggedIn) {
          await this.fetchData(); 
        }
      } catch (error) {
        console.error('Get info failed:', error);
      }
    },
    async autoLogin() {
      try {
        const response = await axios.get('https://productiontracking.usound.com/api/User/GetUserInfo', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        if (response.data.type === "USound") {
          this.isAdmin = true;
        }
        this.usertype = response.data.type;
        if (this.loggedIn) {
          await this.fetchData(); // Fetch data after login
        }
      } catch (error) {
        // Handle login error
        console.error('Get info failed:', error);
      }
    },
    async logout() {
      VueCookies.remove('token');
      this.loggedIn = false;
      window.location.reload();
    },
    async fetchData() {
      if (this.usertype === "USound") {
        await this.fetchPurchaseOrders();
      }
      await this.fetchProductionUnits();
      await this.fetchWafers();
    }
  }
};
</script>

<style scoped>
@font-face {
    font-family: "Nunito Sans";
    font-weight: 400;
    font-style: normal;
    font-display: auto;
    src: local("./fonts/nunito-sans-regular.ttf") format("truetype");
}
*{font-family: 'Nunito Sans', sans-serif;}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #070D12;
  color: #000;
}

.loading {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 20px;
}

button {
  background-color: #3B4D4B;
  color: white;
  border: 1px solid #9AC7B7;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight:600;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #9AC7B7;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  color: #000;
}

th,
td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #aaa;
}

th {
  background-color: #3B4D4B;
  color: #fff;
}

form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

label {
  margin-bottom: 5px;
}

input[type="text"],
input[type="password"] {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  max-width: 250px;
}

input[type="submit"] {
  background-color: #3B4D4B;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

input[type="submit"]:hover {
  background-color: #1D302E;
}
input[type="checkbox"] {
    accent-color: #9ac7b7;
}
.login-container {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

label {
  margin-top: 1em;
}

.popup-form {
  position: fixed;
  top: 10%;
  background: white;
  left: 50%;
  border: 1px solid #1D302E;
  transform: translateX(-50%);
}

form>div {
  display: flex;
  flex-direction: column;
}

.sort-by {
  padding: 0;
}

.production-units>table>thead>tr>th:nth-child(1) {
  white-space: nowrap;
}

.filter-by-form {
  width: 90%;
  flex-direction: row;
  margin-left: 0;
  gap: 10px;
}

.filter-by-form>label {
  margin-top: 0;
}

.active {
  background-color: #9AC7B7;
}

.edit-button {
  padding: 3px;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: scroll;
  height: 800px;
}

.unit-data-container {
  display: flex;
  gap: 20px;
}

.logout-button {
  position: absolute;
  right: 10px;
  left: auto;
}
</style>
